.MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart.MuiInputBase-marginDense.MuiInput-marginDense {
    border: 1px solid #DADADA;
    padding: 10px 12px;
    position: relative;
    font-size: 13px;
    margin-top: 24px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    letter-spacing: 0.1em;
    background-color: white;
}
input#bootstrap-input{
    color: #7F7F80;
    font-family: avenirLight !important;
    font-weight: 600;
    margin-bottom: -6px;
    text-align: left;
}
p.MuiTypography-root.MuiTypography-body1.css-1pnmrwp-MuiTypography-root{
    color: #7F7F80;
    font-family: avenirLight !important;
    font-weight: 600;
    margin-bottom: -5px;
    font-size: small;
}